*,
*::after,
*::before {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

ol,
ul {
  list-style: none;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}
